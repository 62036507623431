html,
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.maksimer {
    &__row {
        max-width: 850px;
        margin: 0 auto;
        padding: 0 25px;
        &.--wide {
            max-width: 1280px;
        }
    }
    &__header {
        padding: 11px 20px;
        background-color: #000;
        text-align: center;
    }
    &__logo {
        img {
            margin-top: 6px;
            height: 40px;
        }
    }
    &__notice {
        &-error,
        &-success {
            background-color: #000;
            font-size: 16px;
            color: #fff;
            padding: 10px 20px;
            border-radius: 6px;
            border: none;
            margin: 15px 0;
        }
        &-error {
            background-color: #ff1643;
        }
        &-success {
            background-color: #9ccc65;
            font-size: 18px;
            text-align: center;
            font-weight: 400;
        }
    }
    &__form {
        margin-bottom: 100px;
        .maksimer__form-header {
            margin-bottom: 40px;
            h1 {
                text-align: center;
                margin: 80px 0 34px;
                font-size: 40px;
                @media (max-width: 992px) {
                    font-size: 28px;
                }
            }
            p {
                text-align: center;
            }
        }

        .wrapper {
            max-width: 18rem;
            padding: 0 0.5rem;
            margin-left: auto;
            margin-right: auto;
            padding-top: 4rem;
        }
        label {
            font-weight: 400;
            display: block;
            margin-bottom: 12px;
            color: #909090;
            border: 1px solid #ddd;
            padding: 8px 14px;
            border-radius: 6px;
            &.disabled {
                opacity: .5;
                background-color: #ddd;
                padding: 8px 14px 6px;
                input {
                    background-color: #ddd;
                }
            }
            input {
                display:block;
                border-radius: 0.25rem;
                border: 1px solid transparent;
                line-height: 1.5rem;
                padding: 0;
                font-size: 1rem;
                width: 100%;
                margin-top: 0.5rem;
            }
        }
        input:focus {outline: none;}
        textarea {
            border: 1px solid #ddd;
            width: 100%;
            border-radius: 0.5rem;
            font-size: 1rem;
            padding: 10px;
            &::placeholder {
                font-size: 1rem;
                color: #909090;
            }
            &:focus {
                outline: none;
            }
        }
        .file-upload {
            font-weight: 700;
            padding: 0;
            border: none;
            color: #000;
            margin: 40px 0 13px;
        }
        ::-webkit-file-upload-button {
            border: 1px solid #ccc;
            padding: 10px 20px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            transition: all .3s;
            &:focus {
                outline: none;
                background: #f0f0f0;
            }
        }
        table {
            border-collapse: collapse;
            border-spacing: 0;
            margin: 50px 0;
            tr {
                td {
                    width: 5%;
                    text-align: center;
                    input {
                        width: 95%;
                        border: 1px solid #ddd;
                        padding: 11px 14px;
                        border-radius: 6px;
                        margin-top: 8px;
                        font-size: 16px;
                        &:disabled {
                            background-color: #f0f0f0;
                            border-color: #f0f0f0;
                        }
                    }
                }
            }

            @media (min-width: 992px) {
                tbody {
                    tr {
                        td {
                            &:nth-child(2) {
                                width: 20%;
                            }
                            &:nth-child(3) {
                                width: 10%;
                            }
                            &:nth-child(7) {
                                width: 10%;
                            }
                        }
                    }
                }
            }
        }


        @media screen and (max-width:991px) {
            .table {
                margin: 0 auto;
                width: 100%;
                border-spacing: 0;
            }
            .table thead {
                position: absolute;
                clip: rect(1px, 1px, 1px, 1px);
                padding: 0;
                border: 0;
                height: 1px;
                width: 1px;
                overflow: hidden;
            }
            .table tbody,
            .table tr,
            .table th,
            .table td {
                display: block;
                padding: 0;
                text-align: left;
                white-space: normal;
            }
            .table tr td,
            .table tr th {
                padding: 2em 1em;
                overflow: hidden;
                position: relative;
                vertical-align: top;
                border: 1px solid #EDF0F1;
                border-top: none;
                width: 100%;
                white-space: normal;
                @media (max-width: 992px) {
                    border: none;
                    border-bottom: 1px solid #EDF0F1;
                }
            }
            .table th[scope="row"] {
                width: 100%;
                text-align: center;
                display: block;
                background-color: #B3BFC6;
                margin: 0 auto;
                padding: .5em 0;
            }
            .table td[data-header]:before {
                content: attr(data-header);
                display: block;
                float: left;
                width: 50%;
                color: #000000;
                font-weight: bold;
                text-align: left;
            }
            .table td[data-header] > * {
                display: block;
                width: 50%;
                float: right;
                clear: right;
                padding-left: 1em;
                margin-top: 0;
            }
        }

        @media screen and (max-width: 600px) {
            .center {
                width: 100%;
            }
        }

        tfoot {
            tr {
                th {
                    padding: 10px 5px;
                    span {
                        display: block;
                        font-size: 14px;
                        font-weight: 400;
                        color: #909090;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    &__date {
        
    }
    &__submit {
        display: block;
        font-size: 24px;
        padding: 15px 40px;
        margin: 0 auto;
        color: #fff;
        background-color: #000000;
        border-radius: 8px;
        border: none;
        width: 100%;
        max-width: 300px;
        transition: all .3s;
        &:hover {
            cursor: pointer;
            transform: translateY(10px);
        }
    }
    &__radio-btn {
        margin-bottom: 15px;
        @media (max-width: 992px) {
            margin-bottom: 25px;
        }
        label {
            display: inline-block;
            color: #000;
            border: none;
            padding: 0;
            margin-right: 20px;
            margin-bottom: 10px;
            @media (max-width: 992px) {
                display: block;
            }
            input {
                display: inline;
                width: auto;
                margin-bottom: 0;
                margin-left: 0;
            }
        }
        p {
            font-weight: 700;
        }
    }
}

#ui-datepicker-div {
    display: none;
    background-color: #fff;
    box-shadow: 0 2px 12px rgba(0,0,0,0.14);
    margin-top: 15px;
    border-radius: 6px;
    padding: 12px;
}
.ui-datepicker-calendar thead th {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;
    color: #c4c4c4;
}
.ui-datepicker-calendar tbody td {
    width: 2.5rem;
    text-align: center;
    padding: 0;
}
.ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 2rem;
    transition: 0.3s all;
    color: #858585;
    font-size: 0.875rem;
    text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {
    background-color: #000;
    color: #fff
}
.ui-datepicker-calendar tbody td a.ui-state-active {
    background-color: #000;
    color: #fff;
}
.ui-datepicker-header a.ui-corner-all {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    border-radius: 0.25rem;
    transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
    background-color: #ECEFF1;
}
.ui-datepicker-header a.ui-datepicker-prev {
    left: 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: 0.5rem;
    background-position: 50%;
    transform: rotate(180deg);
}
.ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 50%;
}
.ui-datepicker-header a>span {
    display: none;
}
.ui-datepicker-title {
    text-align: center;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
}
.ui-datepicker-week-col {
    color: #78909C;
    font-weight: 400;
    font-size: 0.75rem;
}
.ui-state-disabled {
    font-size: 14px;
    color: #eee;
}


.loader {
    display: none;
    position: relative;
    z-index: 999;
    height: 30px;
    width: 30px;
    overflow: visible;
    margin: 36px auto;
}

.loader:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.loader:before, .loader:after{
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 5px solid transparent;
    border-top-color: #000;
}

.loader:before{
    z-index: 100;
    animation: spin 1s infinite;
}

.loader:after{
    border: 5px solid #ddd;
}

@keyframes spin{
    0%{
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100%{
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
